export default [  
  {
    familyID: "1907",
    location: "Boulder/Gunbarrel, CO",
    startDate: "ASAP",
    schedule: "MWF - 1-5:30pm, TueThur 8:30-5:30 ",
    hours: "30-35 hours per week",
    pay: "$28-32+/hour",
    placementLength: "Temporary, until Nov 1",
    nKids: "2 kids, 2 year old + 4 month old",
    familydescription:
      "Family of 4, + Dog",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Aug 25th 2024"
  },
  {
    familyID: "7101",
    location: "Boulder, CO",
    startDate: "ASAP",
    schedule: "Mon, Wed, Thur 2-6:30pm, Tuesdays 2-8PM ",
    hours: "18.5 Guaranteed, additional 5.5 for dates/weekends available",
    pay: "$28-32+/hour",
    placementLength: "School year or longer",
    nKids: "3 Girls, 7.5 yrs, 6.5 yrs, 4 yrs ",
    familydescription:
      "Family of 5, + Dog",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Aug 7th 2024"
  },
  {
    familyID: "7306",
    location: "Boulder, CO",
    startDate: "August 27th, 2024",
    schedule: "Thursdays 3-7PM, 2 adtl days/wk 3-6:30PM",
    hours: "~11 hours, options for date nights/weekends",
    pay: "$30+/hour",
    placementLength: "School year",
    nKids: "2 children, 4 and 2 years",
    familydescription:
      "Family of 4 + dog ",
    jobDescription: `Please email myboulderbabysitter@gmail.com for details`,
    postedDate: "Jun 20, 2024"
  },
  {
    familyID: "6855",
    location: "Wheat Ridge (Denver), CO",
    startDate: "Late August 2024",
    schedule: "Mon-Fri, afterschool 2-5:30pm",
    hours: "15-20/week, but flexible for more hours for the right Nanny",
    pay: "$24-28/hour",
    placementLength: "School year, or long term",
    nKids: "3 year old",
    familydescription:
      "Mom and Dad live separately, 1 child, dog(s) at Dad's home, care takes place at Mom's house",
    jobDescription: `Please inquire or apply online for more info `,
    postedDate: "June 25, 2024"
  }
];
